<template>
  <OtherBanner :type="50" />

  <section class="filter-box" id="pageTop">
    <div class="content">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="competitionStatus"
        @onChange="statusChange"
      />
    </div>
  </section>

  <section class="toolbar">
    <div class="content">
      <div></div>
      <div class="search">
        <div class="search-icon" @click="search">
          <SearchOutlined style="color: #656565" />
        </div>
        <a-input
          class="search-input"
          v-model:value.trim="searchVal"
          :placeholder="$t('please_enter_keywords')"
          allow-clear
          @pressEnter="search"
        />
        <!-- 请输入关键字 -->
      </div>
    </div>
  </section>

  <section class="competition">
    <div class="content">
      <a-spin :spinning="loading">
        <template v-if="dataList.length">
          <div class="cards">
            <div
              class="card"
              v-for="item in dataList"
              :key="item.competitionId"
            >
              <div class="cover">
                <img :src="item.coverImg" alt="cover" />
              </div>
              <div class="detail">
                <div class="text">
                  <h5>
                    <span
                      class="tag gray"
                      v-if="currentTime < item.collectStartTime"
                      >{{ $t("Pub_Tab_NotStart") }}</span
                    >
                    <span
                      class="tag red"
                      v-else-if="currentTime > item.collectEndTime"
                      >{{ $t("XB_HasEnded") }}</span
                    >
                    <span class="tag green" v-else>{{
                      $t("XB_UnderWay")
                    }}</span>
                    {{ item.competitionName }}
                  </h5>
                  <p class="sub">
                    {{ item.showSbuName == 1 ? item.subName : "" }}
                  </p>
                  <p class="rule">{{ item.competitionRule }}</p>
                  <p class="time">
                    {{ $t("SolicitationPeriod") }}：
                    {{ dateFormat(item.collectStartTime) }} ~
                    {{ dateFormat(item.collectEndTime) }}
                  </p>
                </div>
                <router-link
                  class="link"
                  :to="'/competition/detail?competitionId=' + item.competitionId"
                >
                  {{ $t("view_detail") }}
                  <!-- 查看详情 -->
                </router-link>
              </div>
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :defaultPageSize="20"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty v-else style="padding: 50px 0" />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { dateFormat } from "@/utils/tools";
import ListFilter from "@/components/filter/ListFilter.vue";
import OtherBanner from "@/components/banner/OtherBanner.vue";
import { competitionList } from "@/api/competition";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    OtherBanner,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();

    // 状态
    let statusActive = 0;
    const competitionStatus = [
      {
        name: $t("Pub_Tab_NotStart"), // 未开始
        id: 2,
      },
      {
        name: $t("XB_UnderWay"), // 进行中
        id: 1,
      },
      {
        name: $t("XB_HasEnded"), // 已结束
        id: 3,
      },
    ];
    const statusChange = (arr) => {
      statusActive = arr[0];
      getDataList();
    };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getDataList();
      };

    // 赛事
    const dataList = ref([]),
      loading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      getDataList = (scroll = false) => {
        loading.value = true;
        competitionList({
          page: currentPage.value,
          pageSize: 20,
          name: searchVal.value,
          status: statusActive,
          searchType: 1,
        }).then((res) => {
          loading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            dataList.value = res.data.list || [];
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getDataList(true);
      };
    getDataList();

    return {
      dateFormat,
      currentTime: Date.parse(new Date()) / 1000,
      competitionStatus,
      statusChange,
      searchVal,
      search,
      getDataList,
      dataList,
      loading,
      currentPage,
      pageTotal,
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
.filter-box {
  background-color: @color-page-gray;
  padding-bottom: 14px;
  .content {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.15);
    .mixinWrap();
  }
}

.toolbar {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    .mixinFlex(space-between; center);
    padding: 20px 0 16px;
    .crumbs {
      width: calc(100% - 250px);
      font-size: 14px;
      color: #999;
      span {
        cursor: pointer;
        i {
          padding: 0 2px;
        }
        &:hover {
          color: @color-theme;
        }
        &:last-child {
          .noevents();
          i {
            display: none;
          }
          &:hover {
            color: #999;
          }
        }
      }
    }
    .search {
      border: 1px solid #ccc;
      .mixinFlex(space-between; center);
      width: 220px;
      height: 32px;
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      &-icon {
        width: 34px;
        height: 14px;
        .mixinFlex(center; center);
        border-right: 1px solid #bbbbbb;
        cursor: pointer;
      }
      ::v-deep(.ant-input-affix-wrapper-focused) {
        border-color: none;
        box-shadow: none;
      }
      &-input {
        border: none;
        width: calc(100% - 34px);
        height: 100%;
        background-color: #fff;
        padding: 0 8px;
        font-size: 14px;
        ::v-deep(.ant-input) {
          background-color: #fff;
        }
      }
    }
  }
}

.competition {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 15px 0 rgba(148, 148, 148, 0.15);
      padding: 12px 24px;
      .card {
        padding: 12px 0;
        .mixinFlex(space-between);
        border-bottom: 1px solid #f4f4f4;
        &:last-child {
          border-bottom: none;
        }
        .cover {
          .mixinImgWrap(258px; 145px);
          border-radius: 8px;
          overflow: hidden;
        }
        .detail {
          width: calc(100% - 282px);
          height: 145px;
          .mixinFlex(space-between; center);
          .text {
            width: 690px;
            height: 145px;
            h5 {
              font-size: 16px;
              .mixinEllipsis(28px);
              margin-bottom: 10px;
              .tag {
                color: #fff;
                font-size: 14px;
                border-radius: 4px;
                height: 28px;
                line-height: 28px;
                padding: 0 6px;
                margin-right: 6px;
                font-weight: normal;
                display: inline-block;
                &.gray {
                  background-color: #999999;
                }
                &.green {
                  background-color: #22cf33;
                }
                &.red {
                  background-color: #e8673e;
                }
              }
            }
            .sub {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              height: 20px;
              color: #666666;
              margin-bottom: 10px;
            }
            .rule {
              font-size: 14px;
              .mixinEllipsis(44px; 2);
              line-height: 22px;
              color: #666666;
              margin-bottom: 10px;
            }
            .time {
              font-size: 14px;
              color: #666666;
              margin-bottom: 0;
              line-height: 23px;
            }
          }
          .link {
            color: #999;
            font-size: 14px;
            font-weight: 600;
            height: 32px;
            background-color: #fff;
            border-radius: 8px;
            border: 1px solid #999999;
            line-height: 30px;
            padding: 0 20px;
            &:hover {
              color: var(--theme);
              border-color: var(--theme);
            }
          }
        }
      }
    }
  }
}
</style>
